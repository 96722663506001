import React, { Component, lazy, Suspense } from 'react';
import { RotateSpinLoader } from 'react-css-loaders';
import style from './deskWithOPS.module.css';

const AsyncFirstScreen = lazy(() =>
  import('./Components/first-screen/first-container'),
);

const AsyncSecondScreen = lazy(() =>
  import('./Components/second-screen/second-screen'),
);

const AsyncThirdScreen = lazy(() =>
  import('./Components/third-screen/third-screen'),
);

const AsyncFourthScreen = lazy(() =>
  import('./Components/fourthScreen/fourth-screen'),
);

const AsyncFifthScreen = lazy(() =>
  import('./Components/fifthScreen/fifth-screen'),
);

const AsyncSixthScreen = lazy(() =>
  import('./Components/sixthScreen/sixth-screen'),
);
//
const AsyncSeventhScreen = lazy(() =>
  import('./Components/seventhScreen/seventh-screen'),
);
//интернет приймальня 07
const AsyncEihgthScreen = lazy(() =>
  import('./Components/eighthScreen/eighth-screen'),
);
//call-center 08
const AsyncNineScreen = lazy(() =>
  import('./Components/nineScreen/nine-screen'),
);
//09
const AsyncTenScreen = lazy(() =>
  import('./Components/tenScreen/ten-screen'),
);
//10
const AsyncElevenScreen = lazy(() =>
  import('./Components/elevenScreen/eleven-screen'),
);
//11
const AsyncTwelveScreen = lazy(() =>
  import('./Components/twelveScreen/twelve-screen'),
);
const AsyncEvent = lazy(() => import('./Components/event/event'));

const AsyncPortfolio = lazy(() => import('./Components/portfolio/portfolio'));

const AsyncTeam = lazy(() => import('./Components/team/team'));

const AsyncContacts = lazy(() => import('./Components/contacts/contacts'));

const AsyncZmi = lazy(() => import('./Components/ZMI/zmi'));

const AsyncModal = lazy(() => import('./Components/modalForm/modalForm'));

export default class AppDeskWithOPS extends Component {
  state = {
    open: false,
  };

  componentDidMount = () => {
    window.scroll(0, 0);
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div className={style.wrapper}>
        <Suspense fallback={<RotateSpinLoader />}>
          <AsyncFirstScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSecondScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncThirdScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFourthScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFifthScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSixthScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSeventhScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncEihgthScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncNineScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncTenScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncElevenScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncTwelveScreen onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncEvent onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncPortfolio onOpenModal={this.onOpenModal} />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncZmi />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncTeam />
        </Suspense>       
        <Suspense fallback={<div />}>
          <AsyncContacts />
        </Suspense>
        {open && (
          <Suspense fallback={<div />}>
            <AsyncModal open={open} onCloseModal={this.onCloseModal} />
          </Suspense>
        )}
      </div>
    );
  }
}
