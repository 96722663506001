import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import AppDeskWithOPS from '../deskWithOPS';
import ScrollUp from '../Components/scrollUpButton/scrollUpButton';
import informationSiteOfTheEnterprise from './informationSiteOfTheEnterprise/communalPortal';
import OfficeCitizenry from './officeCitizenry/officeCitizenry';
import OfficeCompany from './officeCompany/officeCompany';
import Messages from './messages/messages';
import ChatBot from './chatBot/chatBot';
import CallCenter from './callCenter/callCenter';
import ElectronicArchive from './electronicArchive/electronicArchive';
import InternetReception from './internetReception/internetReception';
import ManagementSystemForManagers from './managementSystemForManagers/managementSystemForManagers';
import MobileAplicationController from './mobileAplicationController/mobileAplicationController';
import MobileApplicationSubscriber from './mobileApplicationSubscriber/mobileApplicationSubscriber';

export default class StartPage extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/" exact component={AppDeskWithOPS} />        
          <Route path="/informationSiteOfTheEnterprise" component={informationSiteOfTheEnterprise} />
          <Route path="/officeCitizenry" component={OfficeCitizenry} />
          <Route path="/officeCompany" component={OfficeCompany} />
          <Route path="/chatBot" component={ChatBot} />
          <Route path="/messages" component={Messages} />
          <Route path="/internetReception" component={InternetReception} />
          <Route path="/callCenter" component={CallCenter} />
          <Route path="/mobileApplicationSubscriber" component={MobileApplicationSubscriber} />
          <Route path="/mobileAplicationController" component={MobileAplicationController} />
          <Route path="/electronicArchive" component={ElectronicArchive} />
          <Route path="/managementSystemForManagers" component={ManagementSystemForManagers} />
        </Switch>
        <ScrollUp />
      </Fragment>
    );
  }
}
