import React, { Component, lazy, Suspense } from 'react';
import { RotateSpinLoader } from 'react-css-loaders';
import style from './officeCompany.module.css';

const AsyncStartScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/startScreen/startScreen'),
);

const AsyncFirstScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/firstScreen/firstScreen'),
);

const AsyncSecondScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/secondScreen/secondScreen'),
);

const AsyncThirdScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/thirdScreen/thirdScreen'),
);

const AsyncFourthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/fourthScreen/fourthScreen'),
);

const AsyncFifthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/fifthScreen/fifthScreen'),
);

const AsyncSixthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/sixthScreen/sixthScreen'),
);

const AsyncSeventhScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/seventhScreen/seventhScreen'),
);



const AsyncFooter = lazy(() =>
  import('../../Components/pagesComponents/componentsCompanyOffice/footer/footer'),
);

const AsyncModal = lazy(() => import('../../Components/modalForm/modalForm'));

export default class officeCompany extends Component {
  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { open } = this.state;
    return (
      <div className={style.wrapper}>
        
        <Suspense fallback={<RotateSpinLoader />}>
          <AsyncStartScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFirstScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSecondScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncThirdScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFourthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFifthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSixthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSeventhScreen />
        </Suspense>
       
        <button
          className={style.button}
          type="button"
          onClick={this.onOpenModal}>
         залишити заявку
        </button>
        <Suspense fallback={<div />}>
          <AsyncFooter />
        </Suspense>
        {open && (
          <Suspense fallback={<div />}>
            <AsyncModal open={open} onCloseModal={this.onCloseModal} />
          </Suspense>
        )}
      </div>
    );
  }
}
