import React from 'react';
import ScrollToTop from 'react-scroll-up';
import Media from 'react-media';
import style from './scrollUpButton.module.css';

const ScrollUp = () => (
  <Media query={{ maxWidth: 1199 }}>
    {matches =>
      matches ? (
        <ScrollToTop style={{ bottom: 10, right: 10 }} showUnder={160}>
          <span className={style.span}>
            <i className="material-icons">arrow_upward</i>
          </span>
        </ScrollToTop>
      ) : (
        <ScrollToTop showUnder={160}>
          <span className={style.span}>
            <i className="material-icons">arrow_upward</i>
          </span>
        </ScrollToTop>
      )
    }
  </Media>
);

export default ScrollUp;
