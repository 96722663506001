import React, { Component, lazy, Suspense } from 'react';
import { RotateSpinLoader } from 'react-css-loaders';
import style from './officeCitizenry.module.css';

const AsyncStartScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/startScreen/startScreen'),
);

const AsyncSecondScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/secondScreen/secondScreen'),
);

const AsyncThirdScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/thirdScreen/thirdScreen'),
);

const AsyncFourthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/fourthScreen/fourthScreen'),
);

const AsyncFifthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/fifthScreen/fifthScreen'),
);

const AsyncSixthScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/sixthScreen/sixthScreen'),
);

const AsyncSeventhScreen = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/seventhScreen/seventhScreen'),
);

const AsyncFooter = lazy(() =>
  import('../../Components/pagesComponents/componentsOfficeCityzen/footer/footer'),
);

const AsyncModal = lazy(() => import('../../Components/modalForm/modalForm'));

export default class officeCitizenry extends Component {
  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { open } = this.state;
    return (
      <div className={style.wrapper}>
        
        <Suspense fallback={<RotateSpinLoader />}>
          <AsyncStartScreen />
        </Suspense>
        {/* <Suspense fallback={<div />}>
          <AsyncFirstScreen />
        </Suspense> */}
        <Suspense fallback={<div />}>
          <AsyncSecondScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncThirdScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFourthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncFifthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSixthScreen />
        </Suspense>
        <Suspense fallback={<div />}>
          <AsyncSeventhScreen />
        </Suspense>
       
        <button
          className={style.button}
          type="button"
          onClick={this.onOpenModal}>
          залишити заявку
        </button>
        <Suspense fallback={<div />}>
          <AsyncFooter />
        </Suspense>
        {open && (
          <Suspense fallback={<div />}>
            <AsyncModal open={open} onCloseModal={this.onCloseModal} />
          </Suspense>
        )}
      </div>
    );
  }
}
